:root {
  --black: #000000;
  --blue: #1e90ff;
  --green: #269a0e;
  --white: #ffffff;
}

.footer-container {
  background-color: var(--green);
  padding: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  color: var(--white);
}

.footer-heading {
  margin-bottom: 10px;
  font-size: 24px;
  justify-content: center;
  text-align: center;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  font-family: calluna;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
  text-align: center;
  font-family: calluna;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1.2em;
  letter-spacing: -.01em;
  text-transform: none;
  white-space: pre-wrap;
}

.footer-link-items li {
  margin-bottom: 5px;
  font-family: calluna;
  font-style: normal;
  line-height: 1.2em;
  letter-spacing: -.01em;
  text-transform: none;
  white-space: pre-wrap;
}

.footer-link-items a {
  color: var(--white);
  text-decoration: none;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-family: calluna;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2em;
  letter-spacing: -.01em;
  text-transform: none;
  white-space: pre-wrap;
}

.footer-link-items a:hover {
  color: var(--white);
  text-decoration: none;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  font-size: 24px;
  color: var(--white);
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-bottom: 16px;
}

.social-logo {
  color: var(--white);
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: var(--white);
  margin-bottom: 16px;
  font-family: calluna;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2em;
  letter-spacing: -.01em;
  text-transform: none;
  white-space: pre-wrap;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 0;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
    margin: 0 auto 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .footer-links {
    padding-top: 0;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
    margin: 0 auto 0 auto;
  }
}
