:root {
  --black: #000000;
  --blue: #1e90ff;
  --green: #269a0e;
  --white: #ffffff;
}

.services-cards {
  padding-right: 10rem;
  padding-left: 10rem;
  background: #fff;
}

.services-cards-wrapper {
  display: flex;
  margin-top: 45px;
}

.services-cards-items {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
}

.services-cards h1 {
  text-align: center;
  font-family: calluna;
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  line-height: 1.2em;
  letter-spacing: -.01em;
  text-transform: none;
  color: #081b33;
  white-space: pre-wrap;
  margin-top: 24px;
}

.services-cards-items h3 {
  font-family: futura-pt;
  font-weight: 900;
  font-style: normal;
  font-size: 12px;
  line-height: 1.2em;
  letter-spacing: .08em;
  text-transform: uppercase;
  color: #269a0e;
  margin-bottom: 24px;
}

.services-cards-items p {
  margin-bottom: 24px;
}

.expertise {
  padding-left: 45px;
  font-family: calluna;
  line-height: 1.5em;  
}

.expertise li {
  font-family: calluna;
  padding: 5px;
}

@media screen and (max-width: 820px) {
  .paragraphs {
    padding: 10px 0 0;
  }

  .paragraphs h1 {
    font-size: 24px;
  }

  .services-cards {
    padding: 10px 0 0;
  }

  .services-cards h1 {
    font-size: 24px;
  }

  .services-cards-wrapper {
    flex-direction: column;
    margin-top: 0;
  }

  .services-cards-items {
    padding: 0 1em;
  }

  .services-cards-items h3 {
    margin: 30px 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .paragraphs {
    padding: 10px 0 0;
  }

  .paragraphs h1 {
    font-size: 24px;
  }

  .services-cards {
    padding: 10px 0 0;
  }

  .services-cards h1 {
    font-size: 24px;
  }

  .services-cards-wrapper {
    flex-direction: column;
    margin-top: 0;
  }

  .services-cards-items {
    padding: 0 1em;
  }

  .services-cards-items h3 {
    margin: 30px 0 15px;
  }
}